<template>
  <form @submit.prevent="login" class="center">
    <h2 class="m-3">Войти по токену contentful</h2>
    <input
      class="form-control m-1"
      v-model="token"
      type="text"
      placeholder="Токен"
    />
    <button
      v-if="state == 0"
      type="submit"
      class="m-1 btn btn-lg btn-outline-success"
    >
      Войти
    </button>
    <button
      v-else-if="state === 1"
      class="m-1 btn btn-lg btn-outline-primary"
    >
      <span>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="sr-only">Вход...</span>
      </span>
    </button>
    <button
      v-else-if="state === 3"
      class="m-1 btn btn-lg btn-outline-danger"
    >
      Ошибка!
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      token: "",
      state: 0
    };
  },
  methods: {
    async login() {
      if(this.state !== 0) return
      try {
        this.state = 1;
        this.token = this.token.trim()
        const user = await this.$createClient(this.token).getCurrentUser();
        this.$store.dispatch("set_token", this.token);
        this.$store.dispatch("set_user", user);
        this.$router.push("/");
        this.state = 2;
      } catch (error) {
        this.$store.dispatch("set_token", "");
        this.state = 3;
      } finally {
        setTimeout(() => (this.state = 0), 2500);
      }
    }
  }
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}
</style>
